import DCCourseDetailsTab from './DCCourseDetailsTab';
import DCCourseListTab from './DCCourseListTab';

export const DCCoursesSuperAdminTab = (props: Record<string, unknown>) => {
  const { detail }: { detail?: string } = props;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">DC Courses</h1>
          <p className="mt-2 text-sm text-gray-700">
            Multiple sessions with multiple mentors to help a user reach their goals.
          </p>
          {detail && <DCCourseDetailsTab dcCourseId={detail} />}
          {!detail && <DCCourseListTab />}
        </div>
      </div>
    </div>
  );
};

export default DCCoursesSuperAdminTab;
