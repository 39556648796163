import Amex from './Amex';
import Default from './Default';
import Discover from './Discover';
import MasterCard from './MasterCard';
import Visa from './Visa';

const getComponentByBrand = (brand: string) => {
  switch (brand) {
    case 'amex':
      return Amex;
    case 'discover':
      return Discover;
    case 'mastercard':
      return MasterCard;
    case 'visa':
      return Visa;
    default:
      return Default;
  }
};

export { getComponentByBrand, Amex, Default, Discover, MasterCard, Visa };
