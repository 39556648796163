import { useQuery } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import LazyLoadedAGGrid from '../../../elements/LazyLoadedAGGrid';
import { Suspense, useRef } from 'react';
import { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';

const HyperlinkCellRenderer = (props: { value: { url: string; displayText: string } }) => {
  const { url, displayText } = props.value;
  return (
    <a href={url} rel="noopener noreferrer">
      {displayText}
    </a>
  );
};

type TInterviewerDetailSuperAdminTabProps = {
  interviewerId: string;
};

type TInterviewerWithInterviews = {
  id: string;
  email: string;
  status: string;
  interviewerInterviewThrottle: number;
  watchlist: boolean;
  goldenButton: boolean;
  interviewerScoringV4: {
    score: number;
    interviewCount: number;
    percentile: number;
    audioQualityAvg: number;
    workWithThemExcitedAvg: number;
    questionQualityAvg: number;
    hintQualityAvg: number;
    negativeExperiencePct: number;
    scoredInterviewStartTimeEarliest?: Date;
    scoredInterviewStartTimeLatest?: Date;
    sufficientFeedbackForScoring: boolean;
  };
  interviews: {
    id: string;
    friendlyId: string;
    includedInScoring: boolean;
    interviewer: { excludeFromScoring: boolean };
    interviewee: {
      workWithThem: boolean;
      workWithThemExcited: boolean;
      questionQuality: number;
      questionHintQuality: number;
      audioQuality: number;
    };
  }[];
};

export const InterviewerDetailSuperAdminTab = (props: TInterviewerDetailSuperAdminTabProps) => {
  const { interviewerId } = props;
  const {
    data: interviewer,
    isLoading,
    error,
  } = useQuery<TInterviewerWithInterviews, Error>({
    queryKey: ['interviewer'],
    queryFn: (): Promise<TInterviewerWithInterviews> =>
      axios.get(`api/interviewers/${interviewerId}`).then((response) => response.data),
  });
  const gridApi = useRef(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const exportToCsv = () => {
    if (gridApi.current) {
      gridApi.current.exportDataAsCsv();
    }
  };

  const gridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error has occurred: {error.message}</div>;

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'id',
      headerName: 'Interview',
      pinned: 'left',
      sortable: true,
    },
    {
      cellRenderer: HyperlinkCellRenderer,
      field: 'friendlyId',
      headerName: 'Friendly Id',
      pinned: 'left',
      sortable: true,
      valueGetter: (params) => ({
        url: `/admin/interviews?friendlyId=${params.data.friendlyId}`,
        displayText: params.data.friendlyId,
      }),
    },
    { field: 'round.orgId', headerName: 'Employer', sortable: true },
    { field: 'round.focus', headerName: 'Focus', sortable: true },
    { field: 'startTime', headerName: 'Start Time', sortable: true },
    { field: 'endTime', headerName: 'End Time', sortable: true },
    {
      headerName: 'Duration',
      sortable: true,
      valueGetter: (params) => {
        const timeDiff = Math.abs(new Date(params.data.endTime).getTime() - new Date(params.data.startTime).getTime());

        const hours = Math.floor(timeDiff / 3600000);
        const minutes = Math.floor((timeDiff % 3600000) / 60000);
        const seconds = Math.floor((timeDiff % 60000) / 1000);

        const deltaFormatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`;
        return deltaFormatted;
      },
    },
    {
      valueGetter: (params) => !!params.data.interviewer.excludeFromScoring,
      headerName: 'Manually excluded',
      cellDataType: 'boolean',
      sortable: true,
      headerTooltip: 'Interview was manually excluded from scoring by an admin',
    },
    { field: 'includedInScoring', headerName: 'Used in scoring', sortable: true },
    { field: 'interviewee.review.workWithThem', headerName: 'Work with them', sortable: true },
    { field: 'interviewee.review.workWithThemExcited', headerName: 'Work with them: excited', sortable: true },
    { field: 'interviewee.review.questionQuality', headerName: 'Question quality', sortable: true },
    { field: 'interviewee.review.questionHintQuality', headerName: 'Hint quality', sortable: true },
    { field: 'interviewee.review.audioQuality', headerName: 'Audio quality', sortable: true },
  ];

  return (
    <div className="align-right my-2 mt-4 flex flex-col">
      <a href="/admin/interviewers">{'<'} Interviewers list</a>
      <h2 className="mt-4">Interviewer</h2>
      <div>
        <span className="font-bold">User id:</span>{' '}
        <a href={`/admin/users/?userId=${interviewerId}`}>{interviewerId}</a>
      </div>
      <div>
        <span className="font-bold">Email:</span> {interviewer.email}
      </div>
      <div>
        <span className="font-bold">Status:</span> {interviewer.status}
      </div>
      <div>
        <span className="font-bold">Golden button:</span> {!!interviewer.goldenButton ? 'Yes' : 'No'}
      </div>
      <div>
        <span className="font-bold">Watchlist:</span> {!!interviewer.watchlist ? 'Yes' : 'No'}
      </div>
      <div>
        <span className="font-bold">Throttle:</span> {interviewer.interviewerInterviewThrottle}
      </div>
      <div>
        <span className="font-bold">Sufficient feedback for scoring:</span>{' '}
        {!!interviewer.interviewerScoringV4.sufficientFeedbackForScoring ? 'Yes' : 'No'}
      </div>
      <div>
        <span className="font-bold">Scored interviews:</span> {interviewer.interviewerScoringV4.interviewCount}
      </div>
      <div>
        <span className="font-bold">Score:</span> {interviewer.interviewerScoringV4.score}
      </div>
      <div>
        <span className="font-bold">Percentile:</span> {interviewer.interviewerScoringV4.percentile}
      </div>
      <div>
        <span className="font-bold">Negative Experience (%):</span>{' '}
        {interviewer.interviewerScoringV4.negativeExperiencePct}
      </div>
      <div>
        <span className="font-bold">Audio Quality (avg):</span> {interviewer.interviewerScoringV4.audioQualityAvg}
      </div>
      <div>
        <span className="font-bold">Excited to work with (avg):</span>{' '}
        {interviewer.interviewerScoringV4.workWithThemExcitedAvg}
      </div>
      <div>
        <span className="font-bold">Question quality (avg):</span> {interviewer.interviewerScoringV4.questionQualityAvg}
      </div>
      <div>
        <span className="font-bold">Hint quality (avg):</span> {interviewer.interviewerScoringV4.hintQualityAvg}
      </div>
      <div>
        <span className="font-bold">Earliest Scored Interview:</span>{' '}
        {interviewer.interviewerScoringV4.scoredInterviewStartTimeEarliest.toString()}
      </div>
      <div>
        <span className="font-bold">Latest Scored Interview:</span>{' '}
        {interviewer.interviewerScoringV4.scoredInterviewStartTimeLatest.toString()}
      </div>
      <h2 className="mt-4">Interviews</h2>
      <Suspense fallback={<div className="my-4">Loading...</div>}>
        <LazyLoadedAGGrid
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          className="ag-theme-alpine my-4 w-full text-xs"
          style={{ height: '500px' }}
          rowData={interviewer.interviews}
          columnDefs={columnDefs}
        />
        <div>
          <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={exportToCsv}>
            Export to CSV
          </button>
        </div>
      </Suspense>
    </div>
  );
};

export default InterviewerDetailSuperAdminTab;
