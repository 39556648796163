import InterviewerDetailSuperAdminTab from './InterviewerDetailTab';
import InterviewerListTab from './InterviewerListTab';

export const InterviewersSuperAdminTab = (props: Record<string, unknown>) => {
  const { detail }: { detail?: string } = props;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Interviewers</h1>
          <p className="mt-2 text-sm text-gray-700">Trial and Paid interviewers.</p>
          {detail && <InterviewerDetailSuperAdminTab interviewerId={detail} />}
          {!detail && <InterviewerListTab />}
        </div>
      </div>
    </div>
  );
};

export default InterviewersSuperAdminTab;
