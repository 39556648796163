import { useQuery, useQueryClient } from '@tanstack/react-query';

import type { ColDef, ColGroupDef, GridOptions, GridReadyEvent, ITooltipParams } from 'ag-grid-community';
import { Suspense, lazy, useRef } from 'react';
import axios from '../../../utils/axios';
import { TDCCourseSummary, TMenteeUserSummary } from './DCCourseListTab';
import {
  formatUserTimeZoneDate,
  SuperAdminHyperlinkField,
  UserTimezoneDateFormatter,
  SuperAdminHyperlinkCellRenderer,
} from '../../superAdminFormatters';
import { useUser } from '../../../userContext';
import Banner from '../../../elements/Banner';
import { Tooltip } from 'react-tooltip';

const LazyLoadedAGGrid = lazy(() => import('../../../elements/LazyLoadedAGGrid'));

export type TGrantDetails = {
  _id: string;
  grantDate: string;
  focus: string;
  round: { _id: string; start: string; end: string };
  interview: { _id: string; status: string; friendlyId: string };
  start: string;
  end: string;
};

type TIntakeSurveySubmissionData = {
  submitted_at: string;
  metadata: Record<string, unknown>;
  answers: Record<string, unknown>;
  questions: Record<string, unknown>;
  form_details: Record<string, unknown>;
};

export type TDCCourseDetails = TDCCourseSummary & {
  mentee: TMenteeUserSummary & {
    practice: { clawedBack: TGrantDetails[]; guaranteed: TGrantDetails[] };
  };
  intakeSurveySubmission: {
    data: TIntakeSurveySubmissionData;
  };
};

type TDCCourseDetailsSuperAdminTabProps = {
  dcCourseId: string;
};

const IntakeSurveyDetails = (props: { submissionData: TIntakeSurveySubmissionData; dcCourseId: string }) => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const submission = props?.submissionData;
  if (!submission?.submitted_at) {
    return <em>Not submitted</em>;
  }

  const refetch = () => {
    axios.get<TDCCourseDetails>(`api/dc-courses/refetch/${props.dcCourseId}`).then(() => {
      queryClient.invalidateQueries({ queryKey: ['dcCourse'] });
    });
  };

  return (
    <div>
      <div>
        <span className="font-bold">Submitted at:</span>{' '}
        {formatUserTimeZoneDate(user.timezone, new Date(submission?.submitted_at).getTime())}
      </div>
      <span className="font-bold">Answers:</span>
      <Banner className="my-2 bg-orange-200">
        <span className="font-semibold">Beta alert:</span> Here we have the survey submission answers ... we have some
        work to display the associated questions.{' '}
      </Banner>
      {submission.form_details ? (
        <textarea
          value={JSON.stringify(submission.form_details, null, 2)}
          readOnly={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        <div>
          <div>Submission question data not available.</div>
          <button className="m-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={refetch}>
            Refetch submission
          </button>
        </div>
      )}
      {submission.answers ? (
        <textarea
          value={JSON.stringify(submission.answers, null, 2)}
          readOnly={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        'Submission answer data not available'
      )}
    </div>
  );
};

export const DCCourseDetailsSuperAdminTab = (props: TDCCourseDetailsSuperAdminTabProps) => {
  const { user } = useUser();
  const { dcCourseId } = props;
  const {
    data: dcCourse,
    isLoading,
    error,
  } = useQuery<TDCCourseDetails, Error>({
    queryKey: ['dcCourse'],
    queryFn: (): Promise<TDCCourseDetails> =>
      axios.get<TDCCourseDetails>(`api/dc-courses/${dcCourseId}`).then((response) => response.data),
  });
  const gridApi = useRef(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const gridOptions: GridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
  };

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      cellRenderer: UserTimezoneDateFormatter(user.timezone),
      field: 'grantDate',
      filter: true,
      headerName: 'Grant creation date',
      headerTooltip: 'DC Course creation time',
      sortable: true,
      tooltipValueGetter: (p: ITooltipParams) => p.value,
    },
    { headerName: 'State: active or clawed back' },
    {
      cellRenderer: SuperAdminHyperlinkCellRenderer<TGrantDetails, TGrantDetails['round']['_id']>(
        (value) => value,
        (data) => `/admin/rounds?roundId=${data.round._id}&activeTab=0`,
        (data) => data.round._id,
        () => `Round Id for this session copied to clipboard`
      ),
      field: 'round._id',
      headerName: 'Round Id',
      sortable: true,
    },
    {
      cellRenderer: SuperAdminHyperlinkCellRenderer<TGrantDetails, TGrantDetails['interview']['friendlyId']>(
        (value) => value,
        (data) => `/admin/interviews?friendlyId=${data.interview.friendlyId}`,
        (data) => data.interview.friendlyId,
        () => `Interview Id for this session copied to clipboard`
      ),
      field: 'interview.friendlyId',
      headerName: 'Interview Friendly Id',
      sortable: true,
    },
    { headerName: 'Payment Intent Id', field: '' },
    {
      cellRenderer: SuperAdminHyperlinkCellRenderer<TGrantDetails, TGrantDetails['_id']>(
        (value) => value,
        (data) => `/admin/users/session-grants/${data._id}`,
        (data) => data._id,
        () => `Grant Id for this session copied to clipboard`
      ),
      field: '_id',
      headerName: 'Grant Id',
      sortable: true,
      pinned: 'left',
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error has occurred: {error.message}</div>;

  const allSessionGrants = [...dcCourse.mentee.practice.guaranteed, ...dcCourse.mentee.practice.clawedBack].sort(
    (a: TGrantDetails, b: TGrantDetails) => new Date(a.grantDate).getTime() - new Date(b.grantDate).getTime()
  );

  const allSessionsCount =
    (dcCourse.mentee.practice?.guaranteed?.length ?? 0) + (dcCourse.mentee.practice?.clawedBack?.length ?? 0);
  const completedSessions = dcCourse.mentee.practice.guaranteed?.filter(
    (grant) => grant.interview?.status === 'TERMINATED'
  ); // TODO: This should prob be a status in the list of conclusive types
  const scheduledSessions = dcCourse.mentee.practice.guaranteed?.filter(
    (grant) => grant.round?._id && !grant.interview?._id
  );

  const clawedBackCount = dcCourse.mentee.practice?.clawedBack?.length ?? 0;
  const completedCount = completedSessions.length;
  const scheduledCount = scheduledSessions.length;
  const unscheduledCount = dcCourse.mentee.practice.guaranteed?.filter((grant) => !grant.round?._id).length;

  const lastCompletedSession = completedSessions.sort(
    (a: TGrantDetails, b: TGrantDetails) => new Date(a.round.start).getTime() - new Date(b.round.start).getTime()
  )?.[0];
  const nextScheduledSession = scheduledSessions.sort(
    (a: TGrantDetails, b: TGrantDetails) => new Date(b.round.start).getTime() - new Date(a.round.start).getTime()
  )?.[0];

  const exportToCsv = () => gridApi.current?.exportDataAsCsv();

  return (
    <div className="align-right my-2 mt-4 flex flex-col">
      <a href="/admin/dc-courses">{'<'} DC Courses</a>
      <h2 className="mt-4">DC Courses</h2>
      <div>
        <span className="font-bold">Course id:</span> {dcCourseId}
      </div>
      <div>
        <a href={`/admin/users?userId=${dcCourse.mentee._id}`}>{dcCourse.mentee.email}</a>
      </div>
      <div>
        <span className="font-bold">Status:</span> {dcCourse.status}
      </div>
      <h3 className="mt-3">Course summary - at purchase / creation</h3>
      <div>
        <span className="font-bold">Org:</span>{' '}
        {dcCourse.org ? (
          <SuperAdminHyperlinkField
            anchorHref={`/admin/org/?orgId=${dcCourse.org._id}`}
            anchorText={dcCourse.org.companyName}
            clipboardValue={dcCourse.org._id}
            clipboardSuccessMessage={`Org Id for "${dcCourse.org.companyName}" copied to clipboard`}
          />
        ) : (
          'Not set'
        )}
      </div>
      <div>
        <span className="font-bold">Focus:</span> {dcCourse.focus ?? 'Not set'}
      </div>
      <div>
        <span className="font-bold">Sessions:</span> {dcCourse.sessions ?? 'Not set'}
      </div>
      <h3 className="mt-3">Session activity</h3>
      <div>
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Last ">
          Last completed:
        </span>{' '}
        {lastCompletedSession ? (
          <>
            <SuperAdminHyperlinkField
              anchorHref={`/admin/rounds/?roundId=${lastCompletedSession.round._id}`}
              anchorText={formatUserTimeZoneDate(user.timezone, new Date(lastCompletedSession.round.start).getTime())}
              clipboardValue={lastCompletedSession.round._id}
              clipboardSuccessMessage={`Round Id "${lastCompletedSession.round._id}" copied to clipboard`}
            />{' '}
            ( Feedback:{' '}
            <SuperAdminHyperlinkField
              anchorHref={`/feedback/${lastCompletedSession.interview.friendlyId}`}
              anchorText={lastCompletedSession.interview.friendlyId}
              clipboardValue={lastCompletedSession.interview.friendlyId}
              clipboardSuccessMessage={`Friendly Id "${lastCompletedSession.interview.friendlyId}" copied to clipboard`}
            />
            )
          </>
        ) : (
          'N/A'
        )}
      </div>
      <div>
        <span className="font-bold">Next scheduled:</span>{' '}
        {nextScheduledSession ? (
          <SuperAdminHyperlinkField
            anchorHref={`/admin/rounds/?roundId=${nextScheduledSession.round._id}`}
            anchorText={formatUserTimeZoneDate(user.timezone, new Date(nextScheduledSession.round.start).getTime())}
            clipboardValue={nextScheduledSession.round._id}
            clipboardSuccessMessage={`Round Id "${nextScheduledSession.round._id}" copied to clipboard`}
          />
        ) : (
          'N/A'
        )}
      </div>
      <Tooltip id="session-count-tooltip" />
      <h3 className="mt-3">Session breakdown</h3>
      <div>
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="All sessions">
          All sessions:
        </span>{' '}
        {allSessionsCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Completed">
          Completed:
        </span>{' '}
        {completedCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Scheduled">
          Scheduled:
        </span>{' '}
        {scheduledCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Unscheduled">
          Unscheduled:
        </span>{' '}
        {unscheduledCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Clawed back">
          Clawed back:
        </span>{' '}
        {clawedBackCount}
      </div>
      <h3 className="mt-3">Session grants</h3>
      <Suspense fallback={<div className="my-4">Loading...</div>}>
        <LazyLoadedAGGrid
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          className="ag-theme-alpine my-4 text-xs"
          style={{ height: '300px' }}
          columnDefs={columnDefs}
          rowData={allSessionGrants}
        />
      </Suspense>
      <div className="my-2 flex flex-col gap-2">
        <div>
          <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={exportToCsv}>
            Export to CSV
          </button>
        </div>
      </div>
      <h3 className="mt-3">Intake survey</h3>
      <IntakeSurveyDetails submissionData={dcCourse.intakeSurveySubmission?.data} dcCourseId={dcCourseId} />
    </div>
  );
};

export default DCCourseDetailsSuperAdminTab;
