import { useQuery } from '@tanstack/react-query';
import type { ColDef, ColGroupDef, GridReadyEvent } from 'ag-grid-community';
import { Suspense, lazy, useMemo, useRef, useState } from 'react';

import axios from '../../../utils/axios';
const LazyLoadedAGGrid = lazy(() => import('../../../elements/LazyLoadedAGGrid'));

type TInterviewer = {
  id: string;
  email: string;
  status: string;
  throttle: number;
  watchlist: boolean;
  goldenButton: boolean;
  interviewerScoringV4: {
    score: number;
    interviewCount: number;
    percentile: number;
    audioQualityAvg: number;
    workWithThemExcitedAvg: number;
    questionQualityAvg: number;
    hintQualityAvg: number;
    negativeExperiencePct: number;
    scoredInterviewStartTimeEarliest?: Date;
    scoredInterviewStartTimeLatest?: Date;
    sufficientFeedbackForScoring: boolean;
  };
};

const HyperlinkCellRenderer = (props: { value: { url: string; displayText: string } }) => {
  const { url, displayText } = props.value;
  return (
    <a href={url} rel="noopener noreferrer">
      {displayText}
    </a>
  );
};

const toFixedFormatter = (fixedDecimalPlaces: number) => (params: { value?: number }) =>
  params.value?.toFixed(fixedDecimalPlaces);

const toDateStringFormatter = (params: { value?: number }) => {
  if (!params.value) return ''; // Handle null or undefined values

  const date = new Date(params.value);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const toTitleCaseFormatter = (params: { value?: string }) =>
  params.value?.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase());

const toThrottleLabel = (params: { value?: number }) => {
  if (params.value == 0) {
    return 'Always throttle';
  }
  if (params.value > 0) {
    return params.value.toString();
  }
  return 'No throttle';
};

export const InterviewerListTab = () => {
  const [showOnlyRecentInterviewers, setShowOnlyRecentInterviewers] = useState<boolean>(true);
  const {
    data: interviewers,
    isLoading,
    error,
  } = useQuery<TInterviewer[], Error>({
    queryKey: ['interviewers'],
    queryFn: (): Promise<TInterviewer[]> => axios.get('api/interviewers/').then((response) => response.data),
  });
  const gridApi = useRef(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const exportToCsv = () => {
    if (gridApi.current) {
      gridApi.current.exportDataAsCsv();
    }
  };

  const filterRecentInterviewers = (interviewers: TInterviewer[]) => {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    return interviewers.filter((interviewer) => {
      const lastActive = new Date(interviewer.interviewerScoringV4.scoredInterviewStartTimeLatest);
      return lastActive > sixMonthsAgo;
    });
  };

  const filteredInterviewers = useMemo(
    () => (showOnlyRecentInterviewers && interviewers ? filterRecentInterviewers(interviewers) : interviewers),
    [showOnlyRecentInterviewers, interviewers]
  );

  const gridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error has occurred: {error.message}</div>;

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      cellRenderer: HyperlinkCellRenderer,
      field: 'id',
      headerName: 'User',
      pinned: 'left',
      sortable: true,
      valueGetter: (params) => ({
        url: `/admin/interviewers/${params.data.id}`,
        displayText: params.data.id,
      }),
    },
    { field: 'email', headerName: 'Email', pinned: 'left', sortable: true },
    { field: 'status', headerName: 'Status', sortable: true, valueFormatter: toTitleCaseFormatter },
    { field: 'goldenButton', headerName: 'Golden Button', sortable: true },
    { field: 'interviewerInterviewThrottle', headerName: 'Throttle', sortable: true, valueFormatter: toThrottleLabel },
    { field: 'watchlist', headerName: 'Watchlist', sortable: true },
    {
      field: 'interviewerScoringV4.sufficientFeedbackForScoring',
      headerName: 'Sufficient Feedback For Scoring',
      sortable: true,
    },
    {
      field: 'interviewerScoringV4.interviewCount',
      headerName: 'Interview Count',
      sortable: true,
    },
    {
      children: [
        {
          field: 'interviewerScoringV4.score',
          headerName: 'Score',
          sortable: true,
          valueFormatter: toFixedFormatter(3),
        },
        {
          field: 'interviewerScoringV4.percentile',
          headerName: 'Percentile',
          sortable: true,
          valueFormatter: toFixedFormatter(3),
        },
        {
          field: 'interviewerScoringV4.negativeExperiencePct',
          headerName: 'Negative Experience (%)',
          sortable: true,
          valueFormatter: toFixedFormatter(2),
        },
        {
          field: 'interviewerScoringV4.audioQualityAvg',
          headerName: 'Audio Quality (avg)',
          sortable: true,
          valueFormatter: toFixedFormatter(2),
        },
        {
          field: 'interviewerScoringV4.workWithThemExcitedAvg',
          headerName: 'Excited to work with (avg)',
          sortable: true,
          valueFormatter: toFixedFormatter(2),
        },
        {
          field: 'interviewerScoringV4.questionQualityAvg',
          headerName: 'Question quality (avg)',
          sortable: true,
          valueFormatter: toFixedFormatter(2),
        },
        {
          field: 'interviewerScoringV4.hintQualityAvg',
          headerName: 'Hint quality (avg)',
          sortable: true,
          valueFormatter: toFixedFormatter(2),
        },
        {
          field: 'interviewerScoringV4.scoredInterviewStartTimeEarliest',
          headerName: 'Earliest Scored Interview',
          sortable: true,
          valueFormatter: toDateStringFormatter,
        },
        {
          field: 'interviewerScoringV4.scoredInterviewStartTimeLatest',
          headerName: 'Latest Scored Interview',
          sortable: true,
          valueFormatter: toDateStringFormatter,
        },
      ],
      headerName: 'Score (All-time Interview History; max 30 most recent)',
    },
  ];

  return (
    <>
      <h2 className="mt-4">Interviewer List</h2>
      <Suspense fallback={<div className="my-2">Loading...</div>}>
        <LazyLoadedAGGrid
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          className="ag-theme-alpine mt-2 mb-4 w-full text-xs"
          style={{ height: '500px' }}
          rowData={filteredInterviewers}
          columnDefs={columnDefs}
        />
      </Suspense>
      <div className="my-2 flex flex-col gap-2">
        <div className="flex justify-between">
          <label className="block">
            <input
              type="checkbox"
              checked={showOnlyRecentInterviewers}
              onChange={(e) => setShowOnlyRecentInterviewers(e.target.checked)}
            />
            Filter interviewers who have a completed at least one (scored) interview in last 6 months.
          </label>
          <div>
            <strong>Interviewers displayed: </strong> {filteredInterviewers.length} of {interviewers.length}.
          </div>
        </div>
        <div>
          <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={exportToCsv}>
            Export to CSV
          </button>
        </div>
      </div>
    </>
  );
};

export default InterviewerListTab;
