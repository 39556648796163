import { CheckoutModalCartViewProps, CartData, TransactionData, TimeSlot, PaymentMethods } from './CheckoutModalTypes';
// @ts-ignore
const stripePublishableKey = window?.CONFIG.stripe.publishableKey;

const unpackCartData = ({ cartData, transactionData }: CheckoutModalCartViewProps) => {
  const { cartItems = [] } = cartData as CartData;
  const currentItem = cartItems[0];
  if (currentItem) {
    const { catalogItem } = currentItem;
    const { interview, name = '', shortDescription = '', skuId = '', unitPrice = 0 } = catalogItem;

    const { org } = interview;
    const { companyIconUrl } = org;
    const {
      timeSlot: { startDate },
    } = transactionData as TransactionData;

    const title = shortDescription?.substring(shortDescription?.indexOf(':') + 1, shortDescription?.length);
    const type = shortDescription?.substring(0, shortDescription?.indexOf(':'));

    const formattedDate = startDate
      ? startDate?.toLocaleDateString(undefined, {
          weekday: 'short',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : '';
    const formattedTime = startDate
      ? startDate?.toLocaleTimeString('en-US', {
          timeZoneName: 'short',
          hour: '2-digit',
          minute: '2-digit',
        })
      : '';

    return {
      formattedDate,
      formattedTime,
      unitPrice,
      skuId,
      type,
      title,
      name,
      companyIconUrl,
    };
  } else {
    return {
      formattedDate: '',
      formattedTime: '',
      unitPrice: 0,
      skuId: '',
      type: '',
      title: '',
      name: '',
      companyIconUrl: '',
    };
  }
};

const getPaymentMethods = async () => {
  const result = await window.angular?.element(document.body).injector().get('OrderService').getKnownPaymentMethods();
  return result;
};

const setDefaultPaymentMethod = async (paymentMethodId: string) => {
  let result = null;
  // @ts-ignore
  if (window?.angular) {
    try {
      result = await window.angular
        // @ts-ignore
        ?.element(document.body)
        .injector()
        .get('OrderService')
        .setDefaultPaymentMethod(paymentMethodId);
    } catch (err) {
      // Log Error but don't throw
    }
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createIntent = async (payload: any) => {
  let result = null;

  // @ts-ignore
  if (!window?.angular) return result;

  // @ts-ignore
  result = await window.angular?.element(document.body).injector().get('OrderService').createIntent(payload);

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const confirmWithStripe = async (clientSecret: string, payload: any) => {
  let result = null;

  // @ts-ignore
  if (!window?.angular) return result;

  result = await window.angular
    // @ts-ignore
    ?.element(document.body)
    .injector()
    .get('OrderService')
    .callStripeConfirmCardPayment(clientSecret, payload);

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const completePurchase = async (payload: any) => {
  let result = null;

  // @ts-ignore
  if (!window?.angular) return result;

  // @ts-ignore
  result = await window.angular?.element(document.body).injector().get('OrderService').completePurchase(payload);

  return result;
};

const scheduleInterview = async (timeSlot: TimeSlot) => {
  let result = null;

  // @ts-ignore
  if (!window?.angular) return result;

  result = await window.angular
    // @ts-ignore
    ?.element(document.body)
    .injector()
    .get('AvailabilityService')
    .scheduleInterview(timeSlot);

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildIntentRequest = (items: any = [], paymentMethodResult: PaymentMethods) => {
  const paymentMethodId = paymentMethodResult[0]?.id || null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const skuItems = items?.map((item: any) => item?.orderPayload);
  return {
    ...(paymentMethodId && { paymentMethodId }), // If paymentMethodId exists, add it as a property
    skuItems,
    shouldSavePaymentInfo: true,
    isAlwaysCreateIntent: true,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildCompletePurchaseRequest = (items: any = [], paymentIntentId = '') => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const skuItems = items?.map((item: any) => item?.orderPayload);
  return {
    ...(paymentIntentId && { paymentIntentId }),
    skuItems,
  };
};

const analytics = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productAdded: async (payload: any) => {
    // @ts-ignore
    await window.angular
      ?.element(document.body)
      .injector()
      .get('AnalyticsService')
      .track(window.CONFIG.const.analytics.eventNames.EVENT_ECOMMERCE_PRODUCT_ADDED, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkoutStarted: async (payload: any) => {
    // @ts-ignore
    await window.angular
      ?.element(document.body)
      .injector()
      .get('AnalyticsService')
      .track(window.CONFIG.const.analytics.eventNames.EVENT_ECOMMERCE_CHECKOUT_STARTED, payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderCompleted: async (payload: any) => {
    // @ts-ignore
    await window.angular
      ?.element(document.body)
      .injector()
      .get('AnalyticsService')
      .track(window.CONFIG.const.analytics.eventNames.EVENT_ECOMMERCE_ORDER_COMPLETED, payload);
  },
};

export {
  analytics,
  unpackCartData,
  createIntent,
  confirmWithStripe,
  completePurchase,
  getPaymentMethods,
  buildIntentRequest,
  buildCompletePurchaseRequest,
  scheduleInterview,
  setDefaultPaymentMethod,
  stripePublishableKey,
};
