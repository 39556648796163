import { useQuery } from '@tanstack/react-query';
import type { ColDef, ColGroupDef, ColumnState, GridOptions, SortChangedEvent } from 'ag-grid-community';
import { Suspense, lazy } from 'react';
import { useEffect, useState } from 'react';

import axios from '../../utils/axios';
import SuperAdminPaginationControls from '../SuperAdminPaginationControls/SuperAdminPaginationControls';
const LazyLoadedAGGrid = lazy(() => import('../../elements/LazyLoadedAGGrid'));

type TRewardLink = {
  _id: string;
  shortCode: string;
  enabled: boolean;
  claimHandler: string;
  claimLimits: {
    perUser: number;
    total: number;
  };
  activePeriod: {
    start: Date;
    end: Date;
  };
};

type PaginationState = {
  page: number;
  totalPages: number;
  limit: number;
  totalItems: number;
};

export const RewardLinksSuperAdminTab = () => {
  const [page, setPage] = useState<number>(1);
  const [sortModels, setSortModels] = useState<ColumnState[]>(null);
  const [limit, setLimit] = useState<number>(10);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState<boolean>(false);

  const fetchRewardLinks = async (page: number, limit: number, ordering: string) => {
    const responsePromise = axios.get('api/reward-links/', {
      params: {
        limit,
        page,
        ordering,
      },
    });
    const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 300));
    const response = await Promise.all([responsePromise, timeoutPromise]).then((values) => values[0]);

    if (!Array.isArray(response.data?.results)) {
      throw new Error('Unexpected response');
    }

    const rewardLinks = response.data.results;
    const totalItems = response.data.count;
    const totalPages = Math.ceil(totalItems / limit);

    return { rewardLinks, pagination: { page, totalPages, limit, totalItems } };
  };

  const { data, error, isFetching, isLoading, refetch } = useQuery<
    { rewardLinks: TRewardLink[]; pagination: PaginationState },
    Error
  >(
    ['rewardLinks', page, limit, sortModels],
    () => {
      const ordering: string = sortModels?.map(({ colId, sort }) => (sort === 'asc' ? colId : `-${colId}`)).join(',');
      return fetchRewardLinks(page, limit, ordering);
    },
    {
      staleTime: 300,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setIsSpinnerVisible(isFetching);
  }, [isFetching]);

  const gridOptions: GridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
    onSortChanged: (event: SortChangedEvent) => {
      setPage(1);
      setSortModels(event.api.getColumnState().filter((col) => col.sort));
    },
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error has occurred: {error.message}</div>;

  const columnDefs: (ColDef | ColGroupDef)[] = [
    { field: '_id', headerName: 'Id', pinned: 'left', sortable: true },
    { field: 'shortCode', headerName: 'Short code', pinned: 'left', sortable: true },
    { field: 'claimHandler', headerName: 'Reward handler', sortable: true },
    { field: 'enabled', headerName: 'Enabled', sortable: true },
    { field: 'issueToNewSignups', headerName: 'New signups', sortable: true },
    { field: 'issueToExistingUsers', headerName: 'Existing users', sortable: true },
    {
      children: [
        { field: 'activePeriod.start', headerName: 'Start date' },
        { field: 'activePeriod.end', headerName: 'End date' },
      ],
      headerName: 'Active period',
    },
    {
      children: [
        { field: 'claimLimits.perUser', headerName: 'Per User' },
        { field: 'claimLimits.total', headerName: 'Total' },
      ],
      headerName: 'Reward Limits',
    },
  ];

  const { rewardLinks: rewardLinksToDisplay, pagination } = data;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Reward Links</h1>
          <p className="mt-2 text-sm text-gray-700">
            Codes for automatically issuing rewards to new and/or existing users.
          </p>
          <div className="align-right my-2 flex"></div>
          <Suspense fallback={<div className="my-4">Loading...</div>}>
            <LazyLoadedAGGrid
              gridOptions={gridOptions}
              className="ag-theme-alpine my-4 w-full text-xs"
              style={{ height: '500px' }}
              rowData={rewardLinksToDisplay}
              columnDefs={columnDefs}
            />
          </Suspense>
          <SuperAdminPaginationControls
            currentPage={page}
            isSpinnerVisible={isSpinnerVisible}
            lastPage={pagination.totalPages}
            limit={limit}
            setLimit={(limit: number) => {
              setLimit(limit);
              setPage(1);
            }}
            setPage={setPage}
            totalItems={pagination.totalItems}
            refetchData={refetch}
          />
        </div>
      </div>
    </div>
  );
};

export default RewardLinksSuperAdminTab;
