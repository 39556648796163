import { useState } from 'react';
import CheckoutModal from './CheckoutModal';
import { ModalData } from './CheckoutModalTypes';

const CheckoutModalContainer = () => {
  const [page, setPage] = useState(0);
  const [isCheckoutModalVisible, setIsCheckoutModalVisible] = useState(false);
  const [inputsCheckoutModal, setInputsCheckoutModal] = useState<ModalData>({} as ModalData);

  // @ts-ignore
  window.showCheckoutModal = (inputs: ModalData) => {
    setInputsCheckoutModal(inputs);
    setPage(0);
    setIsCheckoutModalVisible(true);
  };

  return (
    <CheckoutModal
      open={isCheckoutModalVisible}
      setOpen={setIsCheckoutModalVisible}
      page={page}
      setPage={setPage}
      data={inputsCheckoutModal}
    />
  );
};

export default CheckoutModalContainer;
