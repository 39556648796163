import { useState, useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { buildIntentRequest, createIntent, getPaymentMethods, stripePublishableKey } from './CheckoutModalUtils';
import { CheckoutModalPaymentProps, Intent, PaymentMethods } from './CheckoutModalTypes';
import PaymentComponent from './CheckoutModalPaymentProcessing';
import CheckoutModalPaymentLoading from './CheckoutModalPaymentLoading';

const stripePromise = loadStripe(stripePublishableKey);

const CheckoutModalPayment = (props: CheckoutModalPaymentProps) => {
  const { cartItems = [], total, setOpen, timeSlot, accountBalance } = props;
  const [options, setOptions] = useState<StripeElementsOptions>({
    clientSecret: undefined, // Will be updated once intent is created
    loader: 'always',
    appearance: {
      theme: 'flat',
    },
  });

  const [intent, setIntent] = useState<Intent>();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods>();

  const fetchInitialPaymentData = async () => {
    const paymentMethodsResult = await getPaymentMethods();
    setPaymentMethods(paymentMethodsResult);
    const intentRequest = buildIntentRequest(cartItems, paymentMethodsResult);
    const intentResult = await createIntent(intentRequest);
    setIntent(intentResult);
    setOptions({
      ...options,
      clientSecret: intentResult.clientSecret,
    });
  };

  useEffect(() => {
    fetchInitialPaymentData();
  }, []);

  // Can't show the `Elements` component without a client secret, so swap that out if true
  const hasClientSecret = options?.clientSecret !== undefined;
  const hasPayLaterProgramCredit =
    intent?.intentStatus !== undefined && intent?.intentStatus === 'sufficientAccountBalance';

  return (
    <>
      {(hasClientSecret || hasPayLaterProgramCredit) && (
        <Elements stripe={stripePromise} options={options}>
          <PaymentComponent
            intent={intent}
            cartItems={cartItems}
            timeSlot={timeSlot}
            setOpen={setOpen}
            total={total}
            paymentMethods={paymentMethods}
            accountBalance={accountBalance}
          />
        </Elements>
      )}
      {!hasClientSecret &&
        !hasPayLaterProgramCredit && ( // Default loading
          <CheckoutModalPaymentLoading total={total} />
        )}
    </>
  );
};

export default CheckoutModalPayment;
