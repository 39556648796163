import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CheckoutModalExistingPaymentMethod from './CheckoutModalExistingPaymentMethod';
import CheckoutModalExistingCredit from './CheckoutModalExistingCredit';
import CheckoutModalSavePayment from './CheckoutModalSavePayment';
import {
  buildCompletePurchaseRequest,
  completePurchase,
  setDefaultPaymentMethod,
  scheduleInterview,
  analytics,
} from './CheckoutModalUtils';
import { CheckoutModalPaymentProps } from './CheckoutModalTypes';

const CheckoutModalPaymentProcessing = (props: CheckoutModalPaymentProps) => {
  const { setOpen, cartItems, intent, total, timeSlot, paymentMethods, accountBalance } = props;

  const elements = useElements();
  const stripe = useStripe();

  const [saveNewCard, setSaveNewCard] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [buttonText, setButtonText] = useState('Verifying...');

  const existingPaymentMethod =
    paymentMethods && paymentMethods?.length > 0 ? paymentMethods.find((method) => method._isDefault) : undefined;

  const hasPayLaterProgramCredit = intent?.intentStatus === 'sufficientAccountBalance';

  const hasExistingPaymentMethod = existingPaymentMethod !== (null || undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const completeCheckout = async (): Promise<any> => {
    setButtonText('Purchasing...');

    let confirmResult = null;
    // Call to register payment details with Stripe for this `intent`
    if (!hasPayLaterProgramCredit && hasExistingPaymentMethod && intent) {
      confirmResult = await stripe?.confirmCardPayment(intent.clientSecret, {
        payment_method: existingPaymentMethod?.id,
      });
    } else if (!hasPayLaterProgramCredit && elements !== null) {
      confirmResult = await stripe?.confirmPayment({
        elements,
        confirmParams: {
          return_url: '',
        },
        redirect: 'if_required',
      });
    }

    // Complete Purchase
    const purchaseRequest = buildCompletePurchaseRequest(cartItems, !hasPayLaterProgramCredit ? intent?.id : null);
    const purchaseResult = await completePurchase(purchaseRequest);

    // If we couldn't complete the purchase, throw and bail
    // @ts-ignore
    if (purchaseResult?.error !== undefined) return purchaseResult;
    analytics.orderCompleted({ cartItems, orderId: purchaseResult?._id });

    // Save Default Card information (if desired)
    // Let this fail silently
    if (
      !hasPayLaterProgramCredit &&
      !existingPaymentMethod &&
      saveNewCard &&
      // @ts-ignore
      confirmResult?.paymentIntent.payment_method
    ) {
      await setDefaultPaymentMethod(confirmResult?.paymentIntent.payment_method);
    }

    // Schedule Interview
    // At this point, the user has successfuly purchased an interview
    // If scheduling fails, close window and let user book with existing flow
    try {
      setButtonText('Scheduling...');
      const scheduleResult = await scheduleInterview(timeSlot);
      setButtonText('Refreshing...');
      // @ts-ignore
      await window.refreshSchedulerTimeSlots(scheduleResult, timeSlot, true);
    } catch (err) {
      setOpen(false);
      setProcessing(false);
      setButtonText('Complete Payment');
    }

    setButtonText('Order Complete');
    return null;
  };

  const performPurchase = async () => {
    setProcessing(true);
    try {
      const results = await completeCheckout();
      if (results?.error === undefined) {
        setOpen(false);
      } else {
        // Prevents the state where the work is done, and we flash the button state again before the closing animation closes the modal.
        setButtonText('Complete Payment');
        setProcessing(false);
      }
    } catch (err) {
      // Log Error
      setButtonText('Complete Payment');
      setProcessing(false);
    }
  };

  return (
    <>
      <div className="bg-white px-2 py-3 sm:py-4">
        <div className="mx-2 sm:flex sm:items-start">
          <div className="mt-3 w-full text-center sm:mt-0">
            <Dialog.Title as="h1" className="text-2xl font-bold leading-6 text-gray-900">
              Payment
            </Dialog.Title>
            <div className="mt-3 w-full overflow-hidden p-4">
              {hasPayLaterProgramCredit && <CheckoutModalExistingCredit balance={accountBalance} />}
              {!hasPayLaterProgramCredit && hasExistingPaymentMethod && (
                <CheckoutModalExistingPaymentMethod {...existingPaymentMethod} />
              )}
              {!hasPayLaterProgramCredit && !hasExistingPaymentMethod && (
                <>
                  <PaymentElement />
                  <CheckoutModalSavePayment enabled={saveNewCard} setEnabled={setSaveNewCard} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full sm:static">
        <div className="block w-full bg-white px-4 py-3 sm:pb-4">
          <div className="mt-3 block w-full overflow-hidden border-t-4 border-black bg-white">
            <div className="w-full px-4 py-5 text-right sm:p-6">
              <span className="mr-5 align-top text-xl font-normal text-gray-500">Total</span>
              <span className="text-5xl font-extrabold">${total > 0 ? total / 100 : 0}</span>
            </div>
          </div>
          <button
            type="button"
            className="text-base-legacy inline-flex w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={() => performPurchase()}
            disabled={processing}
          >
            {processing ? buttonText : 'Complete Payment'}
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckoutModalPaymentProcessing;
