import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, ArrowLeftIcon } from '@heroicons/react/outline';
import CheckoutModalCartView from './CheckoutModalCartView';
import CheckoutModalPayment from './CheckoutModalPayment';
import { analytics } from './CheckoutModalUtils';
import { CheckoutModalProps, ModalData, TransactionData, CartData } from './CheckoutModalTypes';

const CheckoutModal = (props: CheckoutModalProps) => {
  const { open = false, setOpen = () => {}, data = {} as ModalData, page = 0, setPage = () => {} } = props;

  // Unpack initial data
  const { transactionData = {} as TransactionData, cartData = {} as CartData } = data;
  const { timeSlot, focus } = transactionData;
  const { cartItems = [] } = cartData;
  const item = cartItems[0];

  // Ref so we can focus here first
  const nextButtonRef = useRef(null);

  // Handle Stripe init here, so it's ready when we get to the Payment Method screen
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (open && item) {
      analytics.productAdded(item);
    }
  }, [open, item]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="react fixed inset-0 z-10 overflow-hidden"
        initialFocus={nextButtonRef}
        onClose={setOpen}
      >
        <div
          className="flex h-full w-full justify-center px-0 pt-0 text-center sm:block sm:h-auto sm:w-screen sm:p-0"
          ref={nextButtonRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="inset-0 bg-gray-500 bg-opacity-75 transition-opacity sm:fixed" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative my-0 mx-0 inline-block h-full w-full transform overflow-hidden bg-white py-0 px-0 text-left align-bottom shadow-xl transition-all sm:my-8 sm:mx-auto sm:h-auto sm:max-w-lg sm:rounded-lg sm:align-middle">
              <div className="absolute top-0 right-0 block pt-4 pr-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="absolute top-0 left-0 block pt-4 pl-4">
                <button
                  type="button"
                  hidden={page === 0}
                  className="rounded-md bg-white text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setPage(0)}
                >
                  <span className="sr-only">Close</span>
                  <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {page === 0 ? (
                <CheckoutModalCartView
                  cartData={cartData}
                  transactionData={transactionData}
                  setTotal={setTotal}
                  total={total}
                  setPage={setPage}
                />
              ) : (
                <CheckoutModalPayment total={total} setOpen={setOpen} timeSlot={{ ...timeSlot, focus }} {...cartData} />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CheckoutModal;
