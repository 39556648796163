import { Dialog } from '@headlessui/react';
import { CheckoutModalPaymentProcessingProps } from './CheckoutModalTypes';
const CheckoutModalPaymentLoading = (
  props: CheckoutModalPaymentProcessingProps
) => {
  const { total } = props;
  return (
    <>
      <div className="bg-white px-2 py-3 sm:py-4">
        <div className="mx-2 sm:flex sm:items-start">
          <div className="mt-3 w-full text-center sm:mt-0">
            <Dialog.Title
              as="h1"
              className="text-2xl font-bold leading-6 text-gray-900"
            >
              Payment
            </Dialog.Title>
            <div className="mt-6 w-full overflow-hidden p-4">
              <div className="flex items-start justify-between rounded-md bg-gray-50 px-6 py-6 ">
                <div className="flex space-x-4">
                  <div className="h-10 w-10 rounded-full bg-slate-200"></div>
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 rounded bg-slate-200"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 h-2 rounded bg-slate-200"></div>
                        <div className="col-span-1 h-2 rounded bg-slate-200"></div>
                      </div>
                      <div className="h-2 rounded bg-slate-200"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full sm:static">
        <div className="block w-full bg-white px-4 py-3 sm:pb-4">
          <div className="mt-3 block w-full overflow-hidden border-t-4 border-black bg-white">
            <div className="w-full px-4 py-5 text-right sm:p-6">
              <span className="mr-5 align-top text-xl font-normal text-gray-500">
                Total
              </span>
              <span className="text-5xl font-extrabold">
                ${total > 0 ? total / 100 : 0}
              </span>
            </div>
          </div>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={() => {}}
            disabled
          >
            Verifying...
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckoutModalPaymentLoading;
