import { getComponentByBrand } from '../CardBrands';
import { PaymentMethod } from './CheckoutModalTypes';

const CheckoutModalExistingPaymentMethod = (props: PaymentMethod) => {
  const { brand, last4, exp_month, exp_year } = props;
  const Card = getComponentByBrand(brand);
  return (
    <div className="mt-5 text-left">
      <div className="flex items-start justify-between rounded-md bg-gray-50 px-6 py-5">
        <div className="flex items-start">
          <Card />
          <div className=" mt-0 ml-4">
            <div className="text-sm font-medium text-gray-900">**** {last4}</div>
            <div className="mt-1 flex items-center text-sm text-gray-600">
              <div>
                Exp: {exp_month}/{exp_year}
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-0 ml-6 flex-shrink-0">
          <div className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm ">
            Default
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutModalExistingPaymentMethod;
