import { useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { unpackCartData, analytics } from './CheckoutModalUtils';
import { CheckoutModalCartViewProps } from './CheckoutModalTypes';
import CheckoutModalPaymentStatement from './CheckoutModalPaymentStatement';

const CheckoutModalCartView = (props: CheckoutModalCartViewProps) => {
  const { cartData, setTotal = () => {}, total, setPage } = props;
  const {
    title,
    type,
    name,
    formattedDate,
    formattedTime,
    unitPrice,
    companyIconUrl,
  } = unpackCartData(props);

  // Update Container with Total
  useEffect(() => {
    setTotal(unitPrice);
  });

  const goToPayment = () => {
    setPage(1);
    analytics.checkoutStarted(cartData);
  };

  return (
    <>
      <div className="bg-white px-2 py-3 sm:py-4">
        <div className="mx-2 sm:flex sm:items-start">
          <div className="mt-3 w-full text-center sm:mt-0">
            <Dialog.Title
              as="h1"
              className="text-2xl font-bold leading-6 text-gray-900"
            >
              Checkout
            </Dialog.Title>
            <div className="mt-5 text-left">
              <div className="flex items-start justify-between rounded-md bg-gray-50 px-6 py-5">
                <h4 className="sr-only">Visa</h4>
                <div className="flex items-start">
                  <img
                    className="h-6 w-auto flex-shrink-0"
                    src={companyIconUrl}
                  />
                  <div className="mt-0 ml-4">
                    <div className="text-base-legacy font-medium text-gray-900">
                      {title}
                    </div>
                    <div className="mt-1 flex items-center text-xs text-gray-400">
                      {name}
                    </div>
                    <div className="mt-1 flex items-center text-xs text-gray-800">
                      {type}
                    </div>
                    <div className="mt-1 flex items-center text-xs text-gray-900">
                      {formattedDate} @ {formattedTime}
                    </div>
                  </div>
                </div>
                <div className=" mt-0 ml-2 flex-shrink-0">
                  <div className="inline-flex items-center rounded-md border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm">
                    ${unitPrice / 100}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full sm:static">
        <CheckoutModalPaymentStatement />
        <div className="block w-full bg-white px-4 py-3 sm:pb-4">
          <div className="mt-3 block w-full overflow-hidden border-t-4 border-black bg-white">
            <div className="w-full px-4 py-5 text-right sm:p-6">
              <span className="mr-5 align-top text-xl font-normal text-gray-500">
                Total
              </span>
              <span className="text-5xl font-extrabold">
                ${total > 0 ? total / 100 : 0}
              </span>
            </div>
          </div>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base-legacy font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={goToPayment}
          >
            Payment Method
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckoutModalCartView;
