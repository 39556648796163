const CheckoutModalPaymentStatement = () => (
  <div className="text-left">
    <div className="flex items-start justify-between rounded-md bg-transparent px-8 py-4">
      <div className="flex items-start">
        <div className="mt-0">
          <div className="text-xs font-normal text-gray-500">
            Please keep in mind that even though this interview is anonymous, there is a real person on the other end.
            Therefore:
            <ul className="list-inside list-disc">
              <li className="mt-2">Please show up on time.</li>
              <li>If you need to cancel, please do so at least 24 hours before your interview.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CheckoutModalPaymentStatement;
