import { CheckoutModalExistingCreditProps } from './CheckoutModalTypes';

const CheckoutModalExistingCredit = (props: CheckoutModalExistingCreditProps) => {
  const { balance } = props;
  return (
    <div className="mt-5 text-left">
      <div className="flex items-start justify-between rounded-md bg-gray-50 px-6 py-5">
        <div className="flex items-start">
          <div className=" mt-0 ml-4">
            <div className="text-sm font-bold text-gray-900">Pay Later Program</div>
            <div className="mt-1 flex items-center text-sm text-gray-600">Remaining Credit</div>
          </div>
        </div>
        <div className=" mt-0 ml-6 flex-shrink-0">
          <div className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm ">
            ${balance / 100}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutModalExistingCredit;
